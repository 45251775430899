import { useI18n } from 'vue-i18n'
import CryptoJS from 'crypto-js'
import getCanadianCities from '@/assets/js/canadian_cities'

export default function () {
  const monthFormat = (value, year_only = false) => {
    const i18n = useI18n({ useScope: 'global' })
    if (value >= 12 && value % 12 === 0) {
      value = value / 12
      return `${i18n.t('wording.year', value)}`
    } else if(value >= 12 && year_only) {
      value = parseFloat(value / 12).toFixed(1)
      return value
    }
    else return `${i18n.t('wording.month', value)}`
  }
  const timesOneHundred = (value) => {
    return value*100
  }

  const timesOneThousand = (value) => {
    return value*1000
  }

  const numberFormatting = (value) => {
    if (typeof value === 'string') {
      value = parseFloat(value)
    }
    return value.toLocaleString('de-DE')
  }

  const encryptMethod = () => {
    return 'AES-256-CBC'
  }

  const encryptMethodLength = () => {
    var encryptMethodVar = encryptMethod()
    // get only number from string.
    // @link https://stackoverflow.com/a/10003709/128761 Reference.
    var aesNumber = encryptMethodVar.match(/\d+/)[0]
    return parseInt(aesNumber)
  }

  const encrypt = (value) => {
    var iv = CryptoJS.lib.WordArray.random(16) // the reason to be 16, please read on `encryptMethod` property.

    var salt = CryptoJS.lib.WordArray.random(256)
    var iterations = 999
    var encryptMethodLengthVar = encryptMethodLength() / 4
    var hashKey = CryptoJS.PBKDF2(process.env.VUE_APP_ENC_KEY, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLengthVar / 8,
      iterations: iterations
    })

    var encrypted = CryptoJS.AES.encrypt(value, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv
    })

    var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)

    var output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iterations: iterations
    }

    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    )
  }

  const decrypt = (encryptedString) => {
    var json = JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
    )

    var salt = CryptoJS.enc.Hex.parse(json.salt)
    var iv = CryptoJS.enc.Hex.parse(json.iv)

    var encrypted = json.ciphertext // no need to base64 decode.

    var iterations = parseInt(json.iterations)
    if (iterations <= 0) {
      iterations = 999
    }
    var encryptMethodLength = 64
    var hashKey = CryptoJS.PBKDF2(process.env.VUE_APP_ENC_KEY, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations: iterations
    })

    var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  const dollarToNumberFormatting = (value, cents = true) => {
    if (typeof value === 'string') {
      value = parseFloat(value.replaceAll(',', ''))
    }
    return value * (cents ? 100 : 1)
  }

  const ordinalSuffix = (i) => {
    var j = i % 10,
      k = i % 100
    if (j == 1 && k != 11) {
      return i + 'st'
    }
    if (j == 2 && k != 12) {
      return i + 'nd'
    }
    if (j == 3 && k != 13) {
      return i + 'rd'
    }
    return i + 'th'
  }

  const dollarFormatting = (value) => {
    if (!value && value !== 0) {
      return
    }
    if (typeof value === 'number') {
      value = String((value / 100).toFixed(2))
    }
    if (value.includes('.')) {
      // get position of first decimal
      // this prevents multiple decimals from being entered
      const decimalPos = value.indexOf('.')

      // split number by decimal point
      let leftSide = value.substring(0, decimalPos)
      let rightSide = value.substring(decimalPos)

      // add ,'s to left side of number
      leftSide = leftSide.replace(/\D/g, '')
      leftSide = leftSide.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      // validate right side
      rightSide = rightSide.replace(/\D/g, '')
      rightSide = rightSide.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      // Limit decimal to only 2 digits
      rightSide = rightSide.substring(0, 2)

      // join number by ,
      value = leftSide + '.' + rightSide
    } else {
      // no decimal entered
      // remove all non-digits
      value = value.replace(/\D/g, '')
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return value
  }

  const capitalize = (value) => {
    if (!value) {
      return ''
    }
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  const dateFormatting = (
    date,
    separator = '-',
    dayFirst = false,
    showYear = true
  ) => {
    let year = date.getFullYear()
    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)

    if (!showYear) return day + separator + month
    if (dayFirst) return day + separator + month + separator + year
    else return year + separator + month + separator + day
  }

  const timeFormatting = (date, separator = ':') => {
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()

    return hours + separator + minutes + separator + seconds
  }

  const dateTimeFormatting = (
    date,
    dateSeparator = '-',
    timeSeparator = ':'
  ) => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10) month = '0' + month
    let day = date.getDate()
    if (day < 10) day = '0' + day
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()

    return (
      year +
      dateSeparator +
      month +
      dateSeparator +
      day +
      ' ' +
      hours +
      timeSeparator +
      minutes +
      timeSeparator +
      seconds
    )
  }

  const riskConversion = (value) => {
    const rates = {
      0: 'R',
      1: 'D',
      2: 'C-',
      3: 'C',
      4: 'C+',
      5: 'B-',
      6: 'B',
      7: 'B+',
      8: 'A-',
      9: 'A',
      10: 'A+'
    }
    return rates[value] ? rates[value] : '?'
  }

  const stateConversion = (value) => {
    const states = ['campaign', 'comingsoon', 'reserved']
    return states.indexOf(value) !== -1 ? states.indexOf(value) : 0
  }

  const maskEmail = (value) => {
    if (!value) {
      return ''
    }

    const principal = value.split('@')[0]
    const domain = value.split('@')[1]

    const start = principal.slice(0, 2)
    const end = principal.slice(-2)

    return start + '**' + end + '@' + domain
  }

  const maskPhoneNumber = (value) => {
    if (!value) {
      return ''
    }
    const end = value.slice(-3)

    return '**' + end
  }

  const isInt = (value) => {
    let reg = /^\d+$/g
    return reg.test(value)
  }

  // Round js number to specific number of decimals
  const roundNumber = (num, dec) => {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec)
  }

  const calculateInstalmentGross = (
    Investment,
    Interest,
    PaymentsN,
    PaymentsNYear
  ) => {
    return roundNumber(
      Investment /
        ((1 - Math.pow(1 + Interest / 100 / PaymentsNYear, -PaymentsN)) /
          (Interest / 100 / PaymentsNYear)),
      4
    )
  }

  const calculateInterestGross = (
    CapitalOutstanding,
    Interest,
    PaymentsNYear
  ) => {
    return roundNumber(CapitalOutstanding * (Interest / 100 / PaymentsNYear), 4)
  }

  const dateTimeFormat = (date) => {
    const i18n = useI18n({ useScope: 'global' })
    let onlyDate = date.split(' ')[0].split('-')
    let weekday = i18n.t(`wording.weekdays.${new Date(date).getDay()}`)
    let day = onlyDate[2]
    let month = i18n.t(`wording.months.${onlyDate[1]}`).slice(0, 3)
    let year = onlyDate[0]
    let onlyTime = date.split(' ')[1].split(':')
    let hours = onlyTime[0]
    let minutes = onlyTime[1]
    return (
      weekday +
      ', ' +
      day +
      ' ' +
      month +
      ' ' +
      year +
      ', ' +
      hours +
      'h' +
      minutes
    )
  }

  const kg2Ton = (kg) => {
    return numberFormatting(Math.round(kg / 1000))
  }

  const slugIt = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replaceAll(' ', '-')
  }

  const prettierDate = (value) => {
    if (!value) {
      return ''
    }
    return new Date(value).toISOString().slice(0, 10)
  }

  // One decimal with comma
  const roundToOneDecimal = (value) => {
    return value.toLocaleString('de-DE', {maximumFractionDigits: 1});
  }

  const isCanadianTimezone = () => {
    const canadianCities = getCanadianCities()

    let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      userTimeZone = userTimeZone.split('/')
      if (userTimeZone.length > 1) {
        userTimeZone = userTimeZone[1]
      }

    return canadianCities.includes(userTimeZone)
  }

  return {
    numberFormatting,
    dollarFormatting,
    timesOneThousand,
    timesOneHundred,
    capitalize,
    dateFormatting,
    timeFormatting,
    dollarToNumberFormatting,
    dateTimeFormatting,
    stateConversion,
    monthFormat,
    riskConversion,
    maskPhoneNumber,
    maskEmail,
    isInt,
    kg2Ton,
    calculateInstalmentGross,
    calculateInterestGross,
    dateTimeFormat,
    ordinalSuffix,
    encrypt,
    decrypt,
    slugIt,
    prettierDate,
    roundToOneDecimal,
    isCanadianTimezone
  }
}
