<template>
  <div v-if="settings">
    <div class="text-xl ml-4 mb-10" v-html="$t('risk_popup.note', {contract: 'Lender Agreement'})"></div>
    <div
      v-if="scrollBtn.show"
      v-on:click="scrollToElement(scrollBtn.boxNumber)" 
      class="
        flex  
        fixed
        h-10
        w-10 
        bottom-5
        right-5 
        border-grey-dark
        border-4
        rounded-full
        animate-pulse
        cursor-pointer
        justify-center
    "
    >
    <p class="flex items-center font-bold text-xl lg:text-sm">
        <img   
          class="w-7 h-7"
          src="@/assets/svg/arrows/down_grey_arrow.svg"
        />
      </p>
    </div>
    <div class="contract-container">
      <!-- summary -->
      <div class="mb-20">
        <p class="title">
          {{ $t('risk_popup.summary_title') }}
        </p>
        <div
          v-for="(line, lineNumber) of $t('risk_popup.summary').split('\n')"
          v-bind:key="lineNumber"
        >
          <span v-html="line"></span>
        </div>
      </div>
  
      <!-- Lender Ack -->
      <div class="py-6 relative align=left">
        <!-- title -->
        <p class="title">
          {{ $t('risk_popup.lend_title') }}
        </p>
        <div class="mb-6" v-html="$t('risk_popup.intro')"></div>
  
        <!-- agreements -->
        <div class="mb-20">
          <div
            v-for="(value, index) in $tm('risk_popup.lender_agreements')"
            :key="value"
          >
            <div class="font-bold">{{ index + 1 + '. ' + value.title }}</div>
            <div class="mb-4" v-html="value.description"></div>
          </div>
        </div>
  
        <!-- signing -->
        <div class="mb-4" v-html="$t('risk_popup.lend_signing.witness')"></div>
        <div class="mb-4">{{ currentDate() }}</div>
  
        <!-- personal -->
        <div>
          <div class="font-bold">
            {{ $t('risk_popup.lend_signing.investor') }}
          </div>
          <div>
            {{ $t('risk_popup.lend_signing.per') }}
          </div>
          <div>{{ $t('risk_popup.lend_signing.signed') }} ref: {{ signature }}</div>
          <div class="mb-4">
            {{ $t('risk_popup.lend_signing.name') }} {{ profile.name }}
            {{ profile.surname }}
          </div>
          <div id="11" class="mb-10">
            <GpButton @click="isOrg ? 
            (sign('witness'), sign('schedule_1', 'retail')) : 
            sign('witness')" :disabled="agree.witness">
              {{ agree.witness ? fullName : $t('risk_popup.sign')}}
            </GpButton>
          </div>
          <ContractDetailBox
            id="1"
            v-if="!agree.witness && !isMobile"
            :copy="'risk_popup.tips.click_to_sign'"
          />
        </div>
  
        <!-- funding -->
        <div class="font-bold">
          {{ $t('risk_popup.lend_signing.gpc_funding') }}
        </div>
        <div>
          {{ $t('risk_popup.lend_signing.per') }}
        </div>
        <div>
          {{ $t('risk_popup.lend_signing.signed') }}
        </div>
        <div>
          {{ $t('risk_popup.lend_signing.blake') }}
        </div>
        <div class="mb-4">
          {{ $t('risk_popup.lend_signing.ceo') }}
        </div>
  
        <!-- securities -->
        <div class="font-bold">
          {{ $t('risk_popup.lend_signing.gpc_securities') }}
        </div>
        <div>
          {{ $t('risk_popup.lend_signing.per') }}
        </div>
        <div>
          {{ $t('risk_popup.lend_signing.signed') }}
        </div>
        <div>
          {{ $t('risk_popup.lend_signing.blake') }}
        </div>
        <div class="mb-20">
          {{ $t('risk_popup.lend_signing.udp') }}
        </div>
      </div>
  
      <div v-if="!isOrg">
        <!-- Risk Disclosure -->
        <div v-if="agree.witness" class="mb-20 py-6 relative align=left">
          <div class="title">
            {{ $t('risk_popup.schedule_one.title_A') }}
          </div>
          <p class="title">
            {{ $t('risk_popup.risk_disclosure') }}
          </p>
          <span
            v-for="(line, lineNumber) of $t('risk_popup.risks').split('\n')"
            v-bind:key="lineNumber"
          >
            <div v-html="line"></div>
          </span>
        </div>
    
        <!-- Risk Acknowledgement -->
        <div v-if="agree.witness" class="mb-20">
          <div class="title">
            {{ $t('risk_popup.schedule_one.title_B') }}
          </div>
          <p class="font-bold text-lg lg:text-2xl text-center">
            {{ $t('risk_popup.risk_title') }}
          </p>
          <div class="flex flex-row mb-4 overflow-x-hidden">
            <div class="border border-black border-8 p-10 w-full">
              <div class="text-center font-bold">
                {{ $t('risk_popup.risk_title_low') }}
              </div>
              <div class="mb-4" v-html="$t('risk_popup.risk_ack')"></div>
              <div class="mb-4">
                {{ $t('risk_popup.print_name') }}
              </div>
              <div>
                {{ $t('risk_popup.date') }}
              </div>
            </div>
            <div class="font-bold text-xl lg:text-2xl mb-9 mt-6 text-center hidden lg:block"
              style="writing-mode: vertical-rl"
            >
              {{ $t('risk_popup.warning_title') }}
            </div>
          </div>
          <div class="mb-4" v-html="$t('risk_popup.cancel')"></div>
          <span
            v-for="(line, lineNumber) of $t('risk_popup.contact').split('\n')"
            v-bind:key="lineNumber"
            class="mb-4"
          >
            <div class="mb-4" v-html="line"></div
          ></span>
          <div class="font-bold">
            {{ $t('risk_popup.notice_title') }}
          </div>
          <div
            v-for="(line, lineNumber) of $t('risk_popup.notice').split('\n')"
            v-bind:key="lineNumber"
          >
            <div v-html="line"></div>
          </div>
        </div>

        <!-- schedule 1 *active* form filled out by user selecting lender type-->
        <div v-if="agree.witness" class="mb-20">
          <p class="title">
            {{ $t('risk_popup.schedule_one.title') }}
          </p>
          <div class="subtitle">{{ $t('risk_popup.schedule_one.subtitle') }}</div>
          <div
            class="mb-4"
            v-html="$t('risk_popup.schedule_one.description')"
          ></div>
          <div class="grey-box">
            {{ $t('risk_popup.schedule_one.instructions_title') }}
          </div>
          <div class="info-box mb-4">
            {{ $t('risk_popup.schedule_one.instructions') }}
          </div>
    
          <!-- eligible users  -->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.eligible.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.eligible.income') }}
            </div>
            <div class="initials">
              <ContractDetailBox
                id="2"
                v-if="!agree.schedule_1 && isEligible && !isMobile"
                :copy="'risk_popup.tips.schedule_1'"
                />
              <GpButton
                id="12"
                v-if="
                  storeData.personal_income >= settings.eligible_personal_income
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'eligible_personal_income')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'eligible_personal_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.eligible.spouse_income') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  storeData.personal_income >= settings.eligible_spouse_income &&
                  storeData.personal_income < settings.accredited_spouse_income
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'spouse_income')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'spouse_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.eligible.net_worth') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  (isEligible &&
                  storeData.net_worth >= settings.eligible_net_worth &&
                  storeData.net_worth < settings.accredited_net_worth)
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'net_worth')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'net_worth' && agree.schedule_1 ? fullName : $t('risk_popup.initials')}}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>
    
          <!-- accredited users -->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.accredited.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.accredited.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.income') }}
            </div>
            <div class="initials">
              <ContractDetailBox
                id="2"
                v-if="!agree.schedule_1 && isAccredited && !isMobile"
                :copy="'risk_popup.tips.schedule_1'"
                />
              <GpButton
                id="12"
                v-if="
                  (isAccredited &&
                  storeData.personal_income >= settings.accredited_personal_income)
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'personal_income')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'personal_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials')}}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.spouse_income') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  storeData.personal_income >= settings.accredited_spouse_income
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'spouse_income')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'spouse_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.cash') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  (isAccredited &&
                  storeData.net_worth >= settings.accredited_assets_value)
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'assets_value')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'assets_value' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.net_worth') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  (isAccredited &&
                  storeData.assets_value >= settings.accredited_net_worth)
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'net_worth')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'net_worth' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>
    
          <!-- family & friends -->
          <div class="sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.family.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.family.header') }}
            </div>
            <div class="description">
              <span v-html="$t('risk_popup.schedule_one.family.owner')"></span>
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.family.family') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.family.friend') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.family.associate') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
          </div>
    
          <!-- not eligible users -->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.not_eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.not_eligible.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.not_eligible.ack') }}
            </div>
            <div class="initials">
              <ContractDetailBox
                id="2"
                v-if="isRetail && !isMobile"
                :copy="'risk_popup.tips.not_eligible'"
                class="-mt-48"
                />
              <GpButton
                id="12"
                v-if="
                  isRetail ||
                  storeData.personal_income <= settings.eligible_spouse_income
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'retail')"
                :disabled="!agree.witness || agree.schedule_1"
              >
                {{ signed[type] === 'retail' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>
        </div>
  
        <!-- schedule 2 not active -->
        <div v-if="agree.schedule_1" class="mb-20">
          <div class="title">
            {{ $t('risk_popup.schedule_two.title') }}
          </div>
          <div class="subtitle">
            {{ $t('risk_popup.schedule_two.subtitle') }}
          </div>
          <div v-html="$t('risk_popup.schedule_two.instructions')"></div>
          <div class="grey-box">
            {{ $t('risk_popup.schedule_two.section1.title') }}
          </div>
          <div class="grey-box">
            {{ $t('risk_popup.schedule_two.section1.sub_title') }}
          </div>
          <div class="info-box">
            {{ $t('risk_popup.schedule_two.description') }}
          </div>
    
          <!-- eligible lenders -->
          <div class="sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.eligible.header') }}
            </div>
            <div
              class="border border-black p-4 lg:col-span-6"
              v-html="$t('risk_popup.schedule_two.eligible.desc')"
            ></div>
            <div class="description">
              {{ $t('risk_popup.schedule_two.eligible.confirm') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_two.eligible.advice') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
          </div>
    
          <!-- accredited lenders -->
          <div class="sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.accredited.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.accredited.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_two.accredited.ack') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
          </div>
    
          <!-- family & friends not available-->
          <div class="sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.family.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.family.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_two.family.ack') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
          </div>
    
          <!-- not eligible lenders -->
          <div class="sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.not_eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.not_eligible.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_two.not_eligible.ack') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
          </div>
    
          <!-- section 2 of schedule 2  -->
          <div v-if="agree.schedule_1" class="mb-20">
            <div class="grey-box">
              {{ $t('risk_popup.schedule_two.section2.title') }}
            </div>
            <div class="grey-box">
              {{ $t('risk_popup.schedule_two.section2.info') }}
            </div>
            <div class="info-box">
              {{ $t('risk_popup.schedule_two.section2.name') }}
            </div>
            <div class="info-box">
              {{ $t('risk_popup.schedule_two.section2.dr') }}
            </div>
            <div class="flex">
              <div class="info-box lg:w-1/2">
                {{ $t('risk_popup.schedule_two.section2.phone') }}
              </div>
              <div class="info-box lg:w-1/2 break-word">
                {{ $t('risk_popup.schedule_two.section2.email') }}
              </div>
            </div>
            <div class="info-box">
              {{ $t('risk_popup.schedule_two.section2.company') }}
            </div>
            <div class="info-box">
              {{ $t('risk_popup.schedule_two.section2.date') }}
            </div>
          </div>
        </div>
      </div>


      <!--  note purchase agreement-->
      <div class="relative">
        <div v-if="agree.schedule_1" class="mb-8">
          <p class="title">
            {{ $t('risk_popup.form_of_note.title') }}
          </p>
          <div class="subtitle">{{ $t('risk_popup.form_of_note.no') }} {{ 1 }}</div>
          <div
            class="text-center lg:text-xl mb-4"
            v-html="$t('risk_popup.form_of_note.gpc')"
          ></div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.holder') }}
          </div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.loan') }}
          </div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.amount') }}
          </div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.total') }}
          </div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.interest') }}
          </div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.service') }}
          </div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.date') }}
          </div>
          <div class="font-bold">
            {{ $t('risk_popup.form_of_note.maturity') }}
          </div>
          <div class="font-bold mb-4">
            {{ $t('risk_popup.form_of_note.max_maturity') }}
          </div>
          <div v-html="$t('risk_popup.form_of_note.maturity_extension')"></div>
          <div
            class="mb-8"
            v-html="
              $t('risk_popup.form_of_note.repayment_dates', {
                date: '[Insert date]'
              })
            "
          ></div>
          <ContractDetailBox
            id="3"
              v-if="agree.schedule_1 && !isMobile"
            :copy="'risk_popup.tips.finish'"
              />
          <!-- accept button -->
          <div id="13" class="mb-10">
            <GpButton @click="next()" :disabled="!agree.schedule_1 || agree.final">
              {{ $t('risk_popup.confirm') }}
            </GpButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import useSecurity from '@/mixins/useSecurity'
import GpButton from '@/components/general/GpButton.vue'
import ContractDetailBox from '@/components/general/ContractDetailBox.vue'


export default {
  components: {
    GpButton,
    ContractDetailBox
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const store = useStore()
    const error = ref(null)
    const close = ref(false)
    const agree = ref({
      witness: false,
      schedule_1: false,
      final: false
    })
    const signed = ref({
      retail: null,
      eligible: null,
      accredited: null
    })

    const signature = ref(null)
    const settingsConfig = require('@/config/settings.config.js')
    const { useSignature } = useSecurity()

    const settings = computed(() => {
      return store.state.general.settings
    })
    const storeData = computed(() => {
      return props.profile.suitability ?? store.state.onboarding.data
    })

    const scrollBtn = ref({
      show: true,
      boxNumber: 1
    })

    const fullName = computed(() => {
      return props.profile.name + ' ' + props.profile.surname
    })

    const innerWidth = ref(window.innerWidth)

    onBeforeMount(async () => {
      await store.dispatch('setRequestSettings', settingsConfig.toString())
      await store.dispatch('fetchMetrics')

      // logic for signatures
      signature.value = useSignature(props.profile)
      if(isMobile.value) scrollBtn.value.boxNumber = 11
    })

    const isEligible = computed(() => {
      return ['eligible', 'eligible_pending'].includes(props.profile.sub_type)
    })

    const isOrg = computed(() => {
      return props.profile.type == 'company'
    })

    const isAccredited = computed(() => {
      return ['accredited', 'accredited_pending'].includes(
        props.profile.sub_type
      )
    })

    const isRetail = computed(() => {
      return ['retail', 'unsuitable', 'unsuitable_pending'].includes(
        props.profile.sub_type
      )
    })

    const type = computed(() => {
      if (isEligible.value) return 'eligible'
      if (isAccredited.value) return 'accredited'
      else return 'retail'
    })

    const sign = (section, reason) => {
      agree.value[section] = true
      signed.value[type.value] = reason
      scrollBtn.value.show = true
      scrollBtn.value.boxNumber ++
    }

    function scrollToElement(boxNumber) {
      const element = document.getElementById(boxNumber)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      scrollBtn.value.show = false
    }
    
    const isMobile = computed(() => {
      return innerWidth.value < 768
    })

    function currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`
      return date
    }

    async function next() {
      axios.post(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles/signAck', 
        {signed: signed.value}
      ).then(() => {
        store.dispatch('fetchProfile')
        store.dispatch('setShowPopup', false)
      }).catch((error) => {
        store.dispatch('setError', error)
      })
    }
    return {
      next,
      sign,
      signed,
      signature,
      agree,
      close,
      currentDate,
      settings,
      storeData,
      error,
      isOrg,
      isAccredited,
      isEligible,
      isRetail,
      type,
      scrollBtn,
      scrollToElement,
      isMobile,
      fullName
    }
  }
}
</script>