<template>
  <div>
    <div class="text-xl ml-4 mb-10" v-html="$t('risk_popup.note', {contract: 'Note Purchase Commitment'})"></div>
    <div 
      v-if="scrollBtn.show"
      v-on:click="scrollToElement(scrollBtn.boxNumber)" 
      class="
        flex  
        fixed
        h-10
        w-10 
        bottom-5
        right-5 
        border-grey-dark
        border-4
        rounded-full
        animate-pulse
        cursor-pointer
        justify-center
        z-60
      "
    >
      <p class="flex items-center font-bold text-xl lg:text-sm">
        <img   
          class="w-7 h-7"
          src="@/assets/svg/arrows/down_grey_arrow.svg"
        />
      </p>
    </div>
    <div class="contract-container">
      <!-- Posting Supplement -->
      <p class="title">
        {{ $t('risk_popup.posting.title') }}
      </p>
      <div class="subtitle">
        {{
          $t('risk_popup.posting.sub_title', {
            date: project.created_at.slice(0, 10),
            campaign: '1000'+project.id+'0'
          })
        }}
      </div>
      <div class="mb-4">
        {{ $t('risk_popup.posting.sub_title2') }}
      </div>
      <div class="mb-4">
        {{ $t('risk_popup.posting.sub_title3') }}
      </div>

      <!-- project specific data rendered with Note information and Borrower details -->
      <div class="mb-4">
        {{ $t('risk_popup.posting.sub_title4') }}
      </div>
      <div class="mb-2">
        <span class="font-bold"> {{ $t('risk_popup.posting.id') }} </span>
        <span>{{ '1000'+project.id+'0' }}</span>
      </div>
      <div class="mb-2">
        <span class="font-bold"> {{ $t('risk_popup.posting.amount') }} </span>
        <span> ${{ dollarFormatting(project.crowdlending_value) }} </span>
      </div>
      <div class="mb-2">
        <span class="font-bold"> {{ $t('risk_popup.posting.grade') }} </span>
        <span> {{ riskConversion(project.risk) }} </span>
      </div>
      <div class="mb-2">
        <span class="font-bold"> {{ $t('risk_popup.posting.rate') }} </span>
        <span> {{ project.funding_rate.interest_investor }} </span>
      </div>
      <div class="mb-2">
        <span class="font-bold"> {{ $t('risk_popup.posting.fee') }} </span>
        <span>0</span>
      </div>
      <div class="mb-4">
        <span class="font-bold"> {{ $t('risk_popup.posting.term') }} </span>
        <span> {{ project.funding_rate.duration_months }} </span>
      </div>
      <div class="mb-4">
        {{
          $t('risk_popup.posting.final', {
            date: project.created_at.slice(0, 10),
            campaign: '1000'+project.id+'0'
          })
        }}
      </div>
      <div class="mb-20">
        <div>
          <span>
            {{ $t('risk_popup.posting.borrower.name') }}
          </span>
          <span>
            {{ project.promoter.name }}
          </span>
        </div>
        <div>
          <span>
            {{ $t('risk_popup.posting.borrower.incorp') }}
          </span>
          <span>
            {{ project.promoter.company_year }}
          </span>
        </div>
        <div>{{ $t('risk_popup.posting.borrower.type') }} Organization</div>
        <div>
          <span>
            {{ $t('risk_popup.posting.borrower.industry') }}
          </span>
          <span>
            {{ project.promoter.company_industry }}
          </span>
        </div>
      </div>

      <!-- Risk Acknowledgement -->
      <div class="mb-20">
        <p class="font-bold text-xl lg:text-2xl text-center">
          {{ $t('risk_popup.risk_title') }}
        </p>
        <div class="flex relative mb-6">
          <div class="border border-black border-8 p-10 overflow-x-hidden">
            <div class="text-center font-bold">
              {{ $t('risk_popup.risk_title_low') }}
            </div>
            <span
              v-for="(line, lineNumber) of $t('risk_popup.risk_ack', {
                amount: amount
              }).split('\n')"
              v-bind:key="lineNumber"
              class="mb-4"
            >
              <div v-html="line"></div
            ></span>
            <div id="1" class="my-4">
              <span> {{ $t('risk_popup.print_name') }} </span>
              <span> {{ profile.name }} {{ profile.surname }}</span>
            </div>
            
            <div class="mb-4">
              {{ $t('risk_popup.date') }} {{ currentDate() }}
            </div>
            <ContractDetailBox
              
              v-if="!agree.risk && !isMobile"
              :copy="'risk_popup.tips.risk'"
              />
            <div id="11"> 
              <GpButton :disabled="agree.risk" @click="isOrg ? 
              (sign ('risk'), sign('schedule_1', 'retail'), sign('schedule_2', 'retail')) :
              sign('risk')">
                {{ agree.risk ? fullName : $t('risk_popup.sign') }}
              </GpButton>
              </div>
          </div>
          <div
            class="font-bold text-xl lg:text-2xl mb-9 mt-6 text-center hidden lg:block"
            style="writing-mode: vertical-rl"
          >
            {{ $t('risk_popup.warning_title') }}
          </div>
        </div>
        <div class="mb-4" v-html="$t('risk_popup.cancel')"></div>
        <div
          v-for="(line, lineNumber) of $t('risk_popup.contact').split('\n')"
          v-bind:key="lineNumber"
          class="mb-4"
        >
          <div v-html="line"></div>
        </div>
        <div class="mb-4 font-bold">
          {{ $t('risk_popup.notice_title') }}
        </div>
        <span
          v-for="(line, lineNumber) of $t('risk_popup.notice').split('\n')"
          v-bind:key="lineNumber"
        >
          <div v-html="line"></div>
        </span>
      </div>

      <div v-if="!isOrg"> 
        <!-- schedule 1 *active* form filled out by user selecting lender type-->
        <div v-if="agree.risk" class="mb-20"
              >
          <p class="title">
            {{ $t('risk_popup.schedule_one.title') }}
          </p>
          <div class="subtitle">
            {{ $t('risk_popup.schedule_one.subtitle') }}
          </div>
          <div
            class="mb-4"
            v-html="$t('risk_popup.schedule_one.description')"
          ></div>
          <div class="grey-box">
            {{ $t('risk_popup.schedule_one.instructions_title') }}
          </div>
          <div class="info-box mb-4">
            {{ $t('risk_popup.schedule_one.instructions') }}
          </div>

          <!-- eligible users -->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.eligible.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.eligible.income') }}
            </div>
            <div class="initials">
              <ContractDetailBox
                v-if="isEligible && !agree.schedule_1 && !isMobile"
                id="2"
                :copy="'risk_popup.tips.schedule_1'"
              />
              <GpButton
                id="12"
                v-if="
                  (isEligible &&
                  storeData.personal_income >= settings.eligible_personal_income &&
                  storeData.personal_income < settings.accredited_personal_income)
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'personal_income')"
                :disabled="!agree.risk || agree.schedule_1"
              >
                {{ signed['schedule_1'][type] === 'personal_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.eligible.spouse_income') }}
            </div>
            <div class="initials">
              <GpButton
                id="12" 
                v-if="isEligible &&
                  storeData.personal_income >= settings.eligible_spouse_income &&
                  storeData.personal_income < settings.accredited_spouse_income
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'spouse_income')"
                :disabled="!agree.risk || agree.schedule_1"
              >
                {{ signed['schedule_1'][type] === 'spouse_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.eligible.net_worth') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  (isEligible &&
                  storeData.net_worth >= settings.eligible_net_worth &&
                  storeData.net_worth < settings.accredited_net_worth)
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'net_worth')"
                :disabled="!agree.risk || agree.schedule_1"
              >
                {{ signed['schedule_1'][type] === 'net_worth' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>

          <!-- accredited users - checks values to narrow choices must match suitability values-->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.accredited.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.accredited.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.income') }}
            </div>
            <div class="initials">
              <ContractDetailBox
                v-if="isAccredited && !agree.schedule_1 && !isMobile"
                id="2"
                :copy="'risk_popup.tips.schedule_1'"
                />
              <GpButton
                id="12"
                v-if="
                  (isAccredited &&
                  storeData.personal_income >= settings.accredited_personal_income)
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'personal_income')"
                :disabled="!agree.risk || agree.schedule_1"
              >
                {{ signed['schedule_1'][type] === 'personal_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.spouse_income') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="isAccredited &&
                  storeData.personal_income >= settings.accredited_spouse_income
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'spouse_income')"
                :disabled="!agree.risk || agree.schedule_1"
              >
                {{ signed['schedule_1'][type] === 'spouse_income' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.cash') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  isAccredited &&
                  storeData.net_worth >= settings.accredited_assets_value
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'assets_value')"
                :disabled="!agree.risk || agree.schedule_1"
              >
                {{ signed['schedule_1'][type] === 'assets_value' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.accredited.net_worth') }}
            </div>
            <div class="initials">
              <GpButton
                id="12"
                v-if="
                  isAccredited &&
                  storeData.assets_value >= settings.accredited_net_worth
                "
                desktopWidth="w-full"
                @click="sign('schedule_1', 'net_worth')"
                :disabled="!agree.risk || agree.schedule_1"
              >
                {{ signed['schedule_1'][type] === 'net_worth' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>

          <!-- family & friends unable to sign -->
          <div class="sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.family.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.family.header') }}
            </div>
            <div class="description">
              <span v-html="$t('risk_popup.schedule_one.family.owner')"></span>
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.family.family') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.family.friend') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.family.associate') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
          </div>

          <!-- not eligible users -->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_one.not_eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.not_eligible.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_one.not_eligible.ack') }}
            </div>
            <div class="initials">
              <div id="12" v-if="isRetail || isPending">
                <ContractDetailBox
                  id="2"
                  v-if="isRetail && !isMobile"
                  :copy="'risk_popup.tips.not_eligible_npc'"
                  class="-mt-48"
                  />
                  <ContractDetailBox
                  id="2"
                  v-else-if="isPending && !isMobile"
                  :copy="'risk_popup.tips.schedule_1_pending'"
                  class="-mt-96"
                  />
                <GpButton             
                desktopWidth="w-full"
                @click="sign('schedule_1', 'retail')"
                :disabled="!agree.risk || agree.schedule_1"
                >
                {{ signed['schedule_1'][type] === 'retail' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
            </div>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>
        </div>

        <!-- schedule 2  *active*-->
        <div v-if="agree.schedule_1" class="mb-20">
          <div class="mb-4">
            <div class="title">
              {{ $t('risk_popup.schedule_two.title') }}
            </div>
            <div class="subtitle">
              {{ $t('risk_popup.schedule_two.subtitle') }}
            </div>
            <div
              class="mb-4"
              v-html="$t('risk_popup.schedule_two.instructions')"
            ></div>
            <div class="grey-box">
              {{ $t('risk_popup.schedule_two.section1.title') }}
            </div>
            <div class="grey-box">
              {{ $t('risk_popup.schedule_two.section1.sub_title') }}
            </div>
            <div class="info-box">
              {{ $t('risk_popup.schedule_two.description') }}
            </div>
          </div>

          <!-- eligible -->
          <div class="relative sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.eligible.header') }}
            </div>
            <div
              class="border border-black p-4 lg:col-span-6"
              v-html="$t('risk_popup.schedule_two.eligible.desc')"
            ></div>
            <div class="description">
              {{
                $t('risk_popup.schedule_two.eligible.confirm', {
                  amount: amount
                })
              }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
            <div class="description">
              {{
                $t('risk_popup.schedule_two.eligible.advice', { amount: amount })
              }}
            </div>
            <div class="initials">
              <ContractDetailBox
                v-if="isEligible && !agree.schedule_2 && !isMobile"
                id="3"
                :copy="'risk_popup.tips.schedule_2'"
                />
              <GpButton
                id="13"
                v-if="isEligible"
                desktopWidth="w-full"
                @click="sign('schedule_2', 'eligible')"
                :disabled="!agree.schedule_1 || agree.schedule_2"
              >
                {{ signed['schedule_2'][type] === 'eligible' && agree.schedule_2 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>

          <!-- accredited user -->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.accredited.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.accredited.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_two.accredited.ack') }}
            </div>
            <div class="initials">
              <ContractDetailBox
                v-if="isAccredited && !agree.schedule_2 && !isMobile"
                id="3"
                :copy="'risk_popup.tips.schedule_2'"
                />
              <GpButton
                id="13"
                v-if="isAccredited"
                desktopWidth="w-full"
                @click="sign('schedule_2', 'accredited')"
                :disabled="!agree.schedule_1 || agree.schedule_2"
              >
                {{ signed['schedule_2'][type] === 'accredited' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
            </div>
          </div>

          <!-- family & friends not available-->
          <div class="sign-table">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.family.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.family.header') }}
            </div>
            <div class="description">
              {{ $t('risk_popup.schedule_two.family.ack') }}
            </div>
            <div class="initials">
              {{ $t('risk_popup.tosign') }}
            </div>
          </div>

          <!-- not eligible user -->
          <div class="sign-table relative">
            <div class="table-header">
              {{ $t('risk_popup.schedule_two.not_eligible.title') }}
            </div>
            <div class="grey-box lg:col-span-6">
              {{ $t('risk_popup.schedule_one.not_eligible.header') }}
            </div>
            <div class="description">
              {{
                $t('risk_popup.schedule_two.not_eligible.ack', {
                  date: currentDate(),
                  amount: amount
                })
              }}
            </div>
            <div class="initials">
              <GpButton
                id="13"
                v-if="isRetail || isPending"
                desktopWidth="w-full"
                @click="sign('schedule_2', 'retail')"
                :disabled="!agree.schedule_1 || agree.schedule_2"
              >
                {{ signed['schedule_2'][type] === 'retail' && agree.schedule_1 ? fullName : $t('risk_popup.initials') }}
              </GpButton>
              <p v-else>
                {{ $t('risk_popup.tosign') }}
              </p>
              <ContractDetailBox
                v-if="isRetail && !isMobile"
                id="3"
                :copy="'risk_popup.tips.schedule_2'"
                class="-mt-48"
                />
                <ContractDetailBox
                v-else-if="isPending && !isMobile"
                id="3"
                :copy="'risk_popup.tips.schedule_2_pending'"
                class="-mt-64"
                />
            </div>
          </div>

          <!-- section 2 of schedule 2  -->
          <div class="grey-box">
            {{ $t('risk_popup.schedule_two.section2.title') }}
          </div>
          <div class="grey-box">
            {{ $t('risk_popup.schedule_two.section2.info') }}
          </div>
          <div class="info-box">
            {{ $t('risk_popup.schedule_two.section2.name') }}
          </div>
          <div class="info-box">
            {{ $t('risk_popup.schedule_two.section2.dr') }}
          </div>
          <div class="flex">
            <div class="info-box w-1/2">
              {{ $t('risk_popup.schedule_two.section2.phone') }}
            </div>
            <div class="info-box w-1/2 break-words">
              {{ $t('risk_popup.schedule_two.section2.email') }}
            </div>
          </div>
          <div class="info-box">
            {{ $t('risk_popup.schedule_two.section2.company') }}
          </div>
          <div class="info-box">
            {{ $t('risk_popup.schedule_two.section2.date') }}
          </div>
        </div>
      </div>

      <!--  note purchase agreement have to show data filled out-->
      <div class="relative" v-if="agree.schedule_2">
        <p class="title">
          {{ $t('risk_popup.form_of_note.title') }}
        </p>
        <div class="subtitle">{{ $t('risk_popup.form_of_note.no') }} {{ '1000'+project.id+'0' }}</div>
        <div
          class="text-center lg:text-xl mb-4"
          v-html="$t('risk_popup.form_of_note.gpc')"
        ></div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.holder') }}</b> {{ profile.name }}
          {{ profile.surname }}
        </div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.loan') }}</b> {{ '1000'+project.id+'0' }}
        </div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.amount') }}</b>
          {{ dollarFormatting(project.crowdlending_value) }}
        </div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.total') }}</b>
          {{ dollarFormatting(amount) }}
        </div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.interest') }}</b>
          {{ project.funding_rate.interest_investor }}
        </div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.service') }}</b>
        </div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.date') }}</b>
        </div>
        <div>
          <b>{{ $t('risk_popup.form_of_note.maturity') }}</b>
        </div>
        <div class="mb-4">
          <b>{{ $t('risk_popup.form_of_note.max_maturity') }}</b> 
        </div>
        <div
          class="mb-4"
          v-html="$t('risk_popup.form_of_note.maturity_extension')"
        ></div>
        <div
          class="mb-8"
          v-html="
            $t('risk_popup.form_of_note.repayment_dates', {
              date: dateTimeFormatting(new Date(project.created_at)).slice(0, 10)
            })
          "
        ></div>
    
        <!-- accept button -->
        <GpButton id="14" @click="next()" :disabled="!agree.schedule_2 || agree.final">
          {{ $t('risk_popup.confirm') }}
        </GpButton>
        <ContractDetailBox
          v-if="agree.schedule_2 && !isMobile"
          id="4"
          :copy="'risk_popup.tips.finish'"
          />
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import useSecurity from '@/mixins/useSecurity'
import GpButton from '@/components/general/GpButton.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import ContractDetailBox from '@/components/general/ContractDetailBox.vue'

export default {
  components: {
    GpButton,
    ContractDetailBox
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    project: {
      type: Object,
      default: () => {}
    },
    amount: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { dollarFormatting, riskConversion, dateTimeFormatting } =
      useGlobalHelpers()
    const store = useStore()
    const error = ref(null)
    const agree = ref({
        risk: false,
        schedule_1: false,
        schedule_2: false,
        final: false
      })
    const signed = ref({
      schedule_1: {
        retail: null,
        eligible: null,
        accredited: null
      },
      schedule_2: {
        retail: null,
        eligible: null,
        accredited: null
      }
    })

    const scrollBtn = ref({
      show: true,
      boxNumber: 1
    })

    const isOrg = computed(() => {
      return props.profile.type == 'company'
    })

    const isEligible = computed(() => {
      return props.profile.sub_type === 'eligible'
    })

    const isAccredited = computed(() => {
      return props.profile.sub_type === 'accredited'
    })

    const isRetail = computed(() => {
      return props.profile.sub_type === 'retail'
    })

    const isPending = computed(() => {
      return ['eligible_pending', 'accredited_pending'].includes(props.profile.sub_type)
    })

    const type = computed(() => {
      if (isEligible.value) return 'eligible'
      if (isAccredited.value) return 'accredited'
      else return 'retail'
    })

    const fullName = computed(() => {
      return props.profile.name + ' ' + props.profile.surname
    })

    const sign = (section, reason = null) => {
      agree.value[section] = true
      if (reason) signed.value[section][type.value] = reason
      scrollBtn.value.show = true
      scrollBtn.value.boxNumber ++
    }
    
    function scrollToElement(boxNumber) {
      const element = document.getElementById(boxNumber)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      scrollBtn.value.show = false
    }

    const innerWidth = ref(window.innerWidth)
    
    const isMobile = computed(() => {
      return innerWidth.value < 768
    })

    const signature = ref(null)
    const { useSignature } = useSecurity()

    const settings = computed(() => {
      return store.state.general.settings
    })

    const settingsConfig = require('@/config/settings.config.js')

    const storeData = computed(() => {
      return props.profile.suitability
    })

    onBeforeMount(async () => {
      await store.dispatch('setRequestSettings', settingsConfig.toString())
      signature.value = useSignature(props.profile)
      if(isMobile.value) scrollBtn.value.boxNumber = 11
    })

    function currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`
      return date.slice(0, 10)
    }

    const next = () => {
      store.dispatch('setSignatures', signed.value)
      store.dispatch('setShowPopup', false)
      const popup = {
        isHidden: true
      }
      store.dispatch('setPopup', popup)
    }

    return {
      next,
      currentDate,
      dateTimeFormatting,
      dollarFormatting,
      riskConversion,
      error,
      agree,
      sign,
      signature,
      settings,
      isOrg,
      isAccredited,
      isEligible,
      isRetail,
      isPending,
      storeData,
      type,
      signed,
      fullName,
      scrollToElement,
      scrollBtn,
      isMobile
    }
  }
}
</script>