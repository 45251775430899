<template>
  <div v-if="profile" class="page flex">
    <div class="lg:pt-20 container flex-grow">
      <Back :to="'/settings'" :title="$t('settings.documents.title')" />

      <!-- static docs -->
      <div class="mt-6">
        <p 
          v-if="currents"
          class="text-13 text-grey-3"
        >
          {{ $t('settings.documents.private') }}
        </p>
        <a 
          v-for="(value, key) in currents" 
          v-bind:key="key"
          :href="value"
          target="_blank"
          class="
            flex
            items-center
            justify-between
            text-right text-sm text-grey-2
            font-bold
            rounded-3xl
            p-7
            mt-4
            cursor-pointer
            bg-white
            green-shadow
          "
        ><img
            src="@/assets/svg/settings/documents.svg"
            alt="documents icon"
            class="mr-4"
          />
          {{ $t('settings.documents.' + key) }}

        </a>
      </div>

      <!-- borrower invoices -->
      <div class="mt-6" v-if="isBorrower">
        <p class="text-13 text-grey-3">
            {{ $t('settings.documents.invoice_upfront_title') }}
        </p>
        <div
          v-for="(invoice, key) in invoices_upfront"
          v-bind:key="key"
          @click="
            openTemplate(
              'invoice_upfront',
              null,
              null,
              getNoteInvestment(invoice)
            )
          "
          target="_blank"
          class="
            flex
            items-center
            justify-between
            text-left text-sm text-grey-2
            font-bold
            rounded-3xl
            p-7
            mt-4
            cursor-pointer
            bg-white
            green-shadow
          "
        >
          <img
            src="@/assets/svg/settings/documents.svg"
            alt="documents icon"
            class="mr-4"
          />
          {{ $t('settings.documents.invoice_upfront') + getNoteInvestment(invoice) }}
        </div>
        <p class="text-13 text-grey-3">
            {{ $t('settings.documents.invoice_ongoing_title') }}
        </p>
        <div
          v-for="(invoice, key) in invoices_ongoing"
          v-bind:key="key"
          @click="
            openTemplate(
              'invoice_ongoing',
              null,
              null,
              getNoteInvestment(invoice)
            )
          "
          target="_blank"
          class="
            flex
            items-center
            justify-between
            text-left text-sm text-grey-2
            font-bold
            rounded-3xl
            p-7
            mt-4
            cursor-pointer
            bg-white
            green-shadow
          "
        >
          <img
            src="@/assets/svg/settings/documents.svg"
            alt="documents icon"
            class="mr-4"
          />
          {{ $t('settings.documents.invoice_ongoing') + getNoteInvestment(invoice) }}
        </div>
      </div>

      <!-- monthly, annual, lendack -->
      <div class="mt-6" v-else>
        <!-- lender ack -->
          <div
            @click="openTemplate('lender_agreement')"
            v-if="lender && profile.lend_ack != null"
            target="_blank"
            class="
              flex
              items-center
              justify-between
              text-left text-sm text-grey-2
              font-bold
              rounded-3xl
              p-7
              mt-4
              cursor-pointer
              bg-white
              green-shadow
            "
          >
            <img
              src="@/assets/svg/settings/documents.svg"
              alt="documents icon"
              class="mr-4"
            />
            {{ $t('settings.documents.' + lender) }}
          </div>
          <br />
        <!-- loop that gets investment docs -->
        <div v-if="investments && investments.length">
          <p class="text-13 text-grey-3">
            {{ $t('settings.documents.investment_title') }}
          </p>
          <div
            v-for="(investment, key) in investments"
            v-bind:key="key"
            @click="
              openTemplate(
                'note_purchase_agreement',
                null,
                null,
                getNoteInvestment(investment)
              )
            "
            target="_blank"
            class="
              flex
              items-center
              justify-between
              text-left text-sm text-grey-2
              font-bold
              rounded-3xl
              p-7
              mt-4
              cursor-pointer
              bg-white
              green-shadow
            "
          >
            <img
              src="@/assets/svg/settings/documents.svg"
              alt="documents icon"
              class="mr-4"
            />
            {{
              $t('settings.documents.note_purchase_confirmation') +
              ' for #' +
              getNoteInvestment(investment) +
              ' on ' +
              getNoteProject(investment)
            }}
          </div>
        </div>

        <!-- loops that gets annual and monthly docs -->
        <div v-if="monthlys && monthlys.length" >
          <div 
          @click="toggleYear(getMonthlysYear(new Date().getFullYear().toString()))"
          class="
              flex
              items-center
              justify-between
              text-left text-sm text-grey-2
              font-bold
              rounded-3xl
              p-7
              mt-4
              cursor-pointer
              bg-white
              green-shadow
            "> <img
              src="@/assets/svg/settings/documents.svg"
              alt="documents icon"
              class="mr-4"
            />{{visibleYears.includes(new Date().getFullYear().toString()) ?
              $t('settings.documents.close') :
              $t('settings.documents.open')
            }}
          </div>
          
          <div v-if="visibleYears.includes(new Date().getFullYear().toString())">
            <div
              v-for="(monthly, key) in monthlys.filter(doc => doc.endsWith(new Date().getFullYear().toString()))"
              v-bind:key="key"
              @click="
                openTemplate(
                  'monthly_statement',
                  getMonthlysMonth(monthly),
                  getMonthlysYear(monthly),
                )
              "
              target="_blank"
              class="
                flex
                items-center
                justify-between
                text-left text-sm text-grey-2
                font-bold
                rounded-3xl
                p-7
                mt-4
                cursor-pointer
                bg-white
                green-shadow
              "
            >
              <img
                src="@/assets/svg/settings/documents.svg"
                alt="documents icon"
                class="mr-4"
              />
              {{
                $t('settings.documents.monthly_statement') +
                ' of ' +
                $t(`wording.months.${getMonthlysMonth(monthly)}`).slice(0, 3) +
                ' ' +
                getMonthlysYear(monthly)
              }}
            </div>
          </div>
        </div>
        <div v-for="(annual, key) in annuals" v-bind:key="key" @click="toggleYear(getMonthlysYear(annual))">
          <div class="
              flex
              items-center
              justify-between
              text-left text-sm text-grey-2
              font-bold
              rounded-3xl
              p-7
              mt-4
              cursor-pointer
              bg-white
              green-shadow
            "> <img
              src="@/assets/svg/settings/documents.svg"
              alt="documents icon"
              class="mr-4"
            />{{visibleYears.includes(getMonthlysYear(annual)) ? 'Close' : 'Open'}} {{getMonthlysYear(annual)}} Statements</div>
        <div v-if="visibleYears.includes(getMonthlysYear(annual))" >
          <!-- annual doc -->
          <div
            @click="
              openTemplate(
                'annual_statement',
                null,
                getMonthlysYear(annual),
              )
            "
            target="_blank"
            class="
              flex
              items-center
              justify-between
              text-left text-sm text-grey-2
              font-bold
              rounded-3xl
              p-7
              mt-4
              cursor-pointer
              bg-white
              green-shadow
            "
          >
            <img
              src="@/assets/svg/settings/documents.svg"
              alt="documents icon"
              class="mr-4"
            />
            {{ $t('settings.documents.annual_statement')
            }}{{ ' of ' + getMonthlysYear(annual) }}
          </div>
          <!-- monthly docs -->
          <div v-if="monthlys && monthlys.length">
            <div
              v-for="(monthly, key) in monthlys.filter(monthly => monthly.endsWith(getMonthlysYear(annual)))"
              v-bind:key="key"
              @click="
                openTemplate(
                  'monthly_statement',
                  getMonthlysMonth(monthly),
                  getMonthlysYear(monthly),
                )
              "
              target="_blank"
              class="
                flex
                items-center
                justify-between
                text-left text-sm text-grey-2
                font-bold
                rounded-3xl
                p-7
                mt-4
                cursor-pointer
                bg-white
                green-shadow
              "
            >
              <img
                src="@/assets/svg/settings/documents.svg"
                alt="documents icon"
                class="mr-4"
              />
              {{
                $t('settings.documents.monthly_statement') +
                ' of ' +
                $t(`wording.months.${getMonthlysMonth(monthly)}`).slice(0, 3) +
                ' ' +
                getMonthlysYear(monthly)
              }}
            </div>
          </div>
        </div>
        </div>
      </div>

      <!-- tax slip -->
      <div class="mt-6" v-if="tax && afterJan">
        <p class="text-13 text-grey-3">
            {{ $t('settings.documents.tax_slip') }}
          </p>
        <div
          @click="openTemplate('tax_slip')"
          target="_blank"
          class="
            flex
            items-center
            justify-between
            text-left text-sm text-grey-2
            font-bold
            rounded-3xl
            p-7
            mt-4
            cursor-pointer
            bg-white
            green-shadow
          "
        >
          <img
            src="@/assets/svg/settings/documents.svg"
            alt="documents icon"
            class="mr-4"
          />
          {{ $t('settings.documents.tax_doc') }}
        </div>
      </div>

      <!-- old static docs -->
      <div class="mt-6">
      <p 
        v-if="olds"
        class="text-13 text-grey-3"
      >
          {{ $t('settings.documents.ammended') }}
      </p>
      <a 
        v-for="(value, key) in olds" 
        v-bind:key="key"
        :href="value"
        target="_blank"
        class="
          flex
          items-center
          justify-between
          text-right text-sm text-grey-2
          font-bold
          rounded-3xl
          p-7
          mt-4
          cursor-pointer
          bg-white
          green-shadow
        "
      ><img
          src="@/assets/svg/settings/documents.svg"
          alt="documents icon"
          class="mr-4"
        />
        {{ key }}
      </a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onBeforeMount } from 'vue'
import axios from 'axios'
import Back from '@/components/general/Back.vue'
import { useStore } from 'vuex'

export default {
  components: {
    Back,
  },
  setup() {
    const store = useStore()
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const isBorrower = computed(() => {
      if (profile.value && profile.value.sub_type === 'borrower')
        return true
      return false
    })
    
    const afterJan = computed(() => {
      const month = new Date().getMonth()
      return process.env.VUE_APP_ENV != 'prod' || month > 0
    })
    const loading = computed(() => {
      return store.state.general.loading
    })

    //key is name and data is link
    const monthlys = ref([])
    const annuals = ref([])
    const visibleYears = ref([])
    const investments = ref([])
    const invoices_upfront = ref([])
    const invoices_ongoing = ref([])
    const currents = ref({})
    const olds = ref({})
    const lender = ref('')
    const tax = ref('')
    onBeforeMount(() => {
      getTemplates()
    })

    // get docs
    const getTemplates = () => {
      store.dispatch('setLoading', true)
      axios
      .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/documents/personal')
      .then((response) => {
        store.dispatch('setLoading', false)
        lender.value = response.data['lender_agreement']
        monthlys.value = response.data['monthly_statement']
        annuals.value = response.data['annual_statement']
        investments.value = response.data['note_purchase_confirmation']
        invoices_upfront.value = response.data['invoices_upfront']
        invoices_ongoing.value = response.data['invoices_ongoing']
        tax.value = response.data['tax_slip']

        // seperate old docs from current docs
        for(let doc in response.data['private']){
          let docSplit = doc.split("_")
          let state = docSplit[0]
          docSplit.shift()
          docSplit.shift()
          let name = docSplit.join("_")

          if(state === 'current')         currents.value[name] = response.data['private'][doc]
          else if(state === 'old') olds.value[name] = response.data['private'][doc]
        }
      })
      .catch = (e) => {
        console.log(e)
        store.dispatch('setLoading', false)
      }
      
      store.dispatch('setLoading', false)
    }

    function toggleYear(year) {
      if (this.visibleYears.includes(year)) {
        this.visibleYears = this.visibleYears.filter(y => y !== year);
      } else {
        this.visibleYears.push(year);
      }
    }

    function openTemplate(template, month=null, year=null, investment=null) {
      store.dispatch('setLoading', true)
      axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/documents/open', {
          params: {
            month: month,
            year: year,
            investment: investment,
            template: template
          },
          responseType: 'arraybuffer'
        })
        .then((response) => {
          store.dispatch('setLoading', false)
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'goparity_download.pdf'
          link.click()
        }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
      })
    }

    const getMonthlysYear = (value) => {
      const montlyArray = value.split('_')
      return montlyArray.pop()
    }

    const getMonthlysMonth = (value) => {
      const montlyArray = value.split('_')
      montlyArray.pop()
      const month = montlyArray.pop()
      return parseInt(month) >= 10 ? month : '0' + month
    }

    const getNoteProject = (value) => {
      const noteArray = value.split('_')
      noteArray.pop()
      return noteArray.pop()
    }

    const getNoteInvestment = (value) => {
      const noteArray = value.split('_')
      return noteArray.pop()
    }

    return {
      getTemplates,
      openTemplate,
      olds,
      currents,
      profile,
      isBorrower,
      afterJan,
      loading,
      monthlys,
      annuals,
      investments,
      invoices_upfront,
      invoices_ongoing,
      lender,
      tax,
      toggleYear,
      visibleYears,
      getMonthlysMonth,
      getMonthlysYear,
      getNoteProject,
      getNoteInvestment
    }
  }
}
</script>

<style lang="sass" scoped></style>
